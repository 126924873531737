import React from "react"
import { Link } from "gatsby"
import { Header, Footer } from "../components"
import { urlWithSession } from "../utils"

export default function EnConstruccion() {
  return (
    <>
      <Header />
      <div className="construction-page">
        <div className="content-container">
          <span className="content">
            Estamos trabajando en esta sección, pronto estará disponible
          </span>
          <Link to={urlWithSession("/")}>
            <button className="back-button">VOLVER AL HOME</button>
          </Link>
        </div>
      </div>
      <Footer />
      <div className="overlay" />
    </>
  )
}
